<template>
  <div id="app">
    <div id="nav">
      <Appbar/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import Appbar from '@/components/Appbar'
export default {
  components:{
    Appbar
  }
}
</script>
<style scoped>
#app{
  overflow:hidden;
  font-family: Century Gothic ;
  white-space: pre-wrap !important;
}
</style>