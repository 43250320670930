<template>
  <div id="contact">
          <div class="container">
            <div class="row jsutify-content-center"> 
              <div class="col-12">
                <form>
                  <div class="form-row justify-content-center">
                    <div class="col-md-10">
                      <h2 class="py-2">Get In Touch</h2>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group pt-3">
                    <input type="text" class="form-control" placeholder="Name" />
                  </div>
                    </div>
                <div class="col-md-5">
                    <div class="form-group pt-3">
                    <input type="text" class="form-control" placeholder="Email Address" />
                  </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group pt-3">
                    <input type="number" class="form-control" placeholder="contact" />
                  </div>
                </div>
                  <div class="col-md-5">
                    <button class="btn btn-dark mt-3 px-4" type="submit">Sign Up</button>
                  </div>
                  </div>
                </form>
          </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="container">
             <div class="col-12 my-5">
            <h3 class="text-center">Get the latest news</h3>
            <p class="text-center">Subscribe for our news letter and get news in your in box every week! We hate spam, so no worries about it.</p>
            <p class="text-center">
               <button class="btn btn-dark">SUBSCRIBE</button>
            </p>
          </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* CONTACT */
#contact {
    background: #1fa0f1;
    padding:100px 0;
}
h2{
  font-weight: 800;
}
h3{
  color:#000;
  font-weight: 800;
  font-size: 1.8em;
}
p{
  color:#fff;
  font-weight: bolder;
  font-size: 1.3em;
}
@media screen and (max-width:500px) {
  .form-container{
    width:80%;
  }
}
</style>