<template>
  <div>
    <HeroComponent/>
    <AboutComponent />
    <Aim />
    <Contact/>
    <Footer/>

  </div>
</template>

<script>
import HeroComponent from '@/components/HeroComponent'
import AboutComponent from '@/components/AboutComponent'
import Aim from '@/components/Aims'
import Contact from '@/components/Contact'
import Footer from '@/components/footer'
  export default {
    components:{
      HeroComponent,
      AboutComponent ,
      Aim ,
      Contact,
      Footer
    }
  }
</script>

<style lang="scss" scoped>

</style>