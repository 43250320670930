<template>
    <div class="bg-contact">
        <footer class="pt-4">
            <h5 class="text-center pt-3 text-white">Copyright CredMap &copy;{{new Date().getFullYear()}} All Rights Reserved.</h5>
        </footer>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
    .bg-contact{
       background:#1b1c1e;
       height: 100px;
    }
</style>