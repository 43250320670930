<template>
  <nav class="navbar navbar-expand-lg  fixed-top navbar-light bg-light ">
    <div class="container">
      <a class="navbar-brand" href="#home">
        <img src="../assets/CREDMAP LOGO 4.png" height="50" alt="">
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#home">Home </a>
          </li>
          <li class="nav-item text-danger">
            <a class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#features">Features</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="#contact">Signup</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
$(window).scroll(function() {
  $("nav").toggleClass("scrolled", $(this).scrollTop() > 200);
});
export default {};
</script>

<style scoped>
nav{
  background-color:#fff  !important;;
}
.navbar-light .nav-link {
  text-transform: uppercase;
  margin-right: 30px;
  font-size: 20px;  
  color:#000 !important;
  font-weight: bold;
}

.bg-light.scrolled {
  background: #fff !important;
}

</style>
