<template>
    <div class="container-fluid main-container" id="features">
        <div class="row">
            <div class="col">
                <h2 class="text-center mb-5" data-aos="zoom-in" data-aos-duration="4000">An Obsessive focus on Transformational Change Systems</h2>
            </div>
        </div>
        <div class="row justify-content-center pt-4">
            <div class="col-md-6 credmap-card" data-aos="zoom-in-left" data-aos-duration="3500">
               <div class="card">
                   <div class="card-header">
                       <div class="row justify-content-center">
                           <div class="col-md-3 text-center">
                            <strong class="text-dark"><i class="fas fa-bus fa-4x"></i></strong>
                            </div>
                            <div class="col-md-9 ">
                                <h3 class="text-center">Public & Civic Integrity Systems</h3>
                            </div>
                       </div>
                   </div>
                   <div class="card-body">
                        <p>Our Credgraph platform is a pioneering push into a new domain of “accountability scaling” and “integrity management” across public and private sectors. Through a combination of deft technology and social marketing, CredGraph is expanding the bounds of governance at multiple levels.</p>
                   </div>
               </div>
            </div>
            <div class="col-md-6 credmap-card" data-aos="zoom-right" data-aos-duration="3500">
                <div class="card">
                   <div class="card-header">
                     <div class="row">
                         <div class="col-md-3 text-center">
                             <strong><i class="fas fa-network-wired fa-4x"></i></strong>
                         </div>
                         <div class="col-md-9">
                             <h3 class="text-center">Transactional Network Efficiency</h3>
                         </div>
                     </div>
                   </div>
                   <div class="card-body">
                       <p>Be they payment systems or settlement platforms, distributed ledgers or wholesale merchant banking software, the desirable characteristics of security, reliabilty and efficiency require expertise in creating end-to-end designs for solutions that integrate seamlessly into legacy operations whilst pushing entire ecosystems forward.</p>
                   </div>
               </div>
            </div>
        </div>
        <div class="row justify-content-center py-5" data-aos="zoom-in-left" data-aos-duration="3000">
            <div class="col-md-6">
                 <div class="card">
                   <div class="card-header">
                   <div class="row">
                         <div class="col-md-3 text-center">
                          <strong><i class="fas fa-dna fa-4x"></i></strong>
                     </div>
                     <div class="col-md-9">
                         <h3 class="text-center">Ecosystem Operating Systems</h3> 
                     </div>
                   </div>
                   </div>
                   <div class="card-body">
                       <p>If there is one area we understand above all else it is how ecosystems have core DNA that can be genetically engineered to grow and scale differently than their “phenotypes” might suggest. Whilst many strategy firms talk about how to capture value through ecosystem orchestration, few have the execution chops to go all the way with the client.</p>
                   </div>
               </div>
            </div>

            <div class="col-md-6 credmap-card" data-aos="zoom-in-right" data-aos-duration="3000">
                <div class="card">
                   <div class="card-header">
                       <div class="row">
                           <div class="col-md-3 text-center">
                             <i class="fas fa-drafting-compass fa-4x"></i>
                            </div>
                            <div class="col-md-9">
                                <h3 class="text-center">Culture-Shifting Design & Strategy</h3>
                            </div>
                         </div>
                   </div>
                   <div class="card-body">
                      <p>Very few mould-breaking changes can occur without a frontal engagement with narrative-shifting. Persuasive communications are definitely an important part of any such effort, but they are rarely effective without the powerful combination of thought leadership and norm-setting at the right time targeting the right decision makers.</p>
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.main-container {
    padding:100px;
    background: #00000008;
}
.main-container h2{
    font-weight: 900;
}
.card-header{
    background:#fff;
}
.card {
    height:300px;
    background: #fff;
    margin-bottom:20px ;
}
.card p{
    font-size: 1.1em;
    line-height: 1.5em;
}
.card h3{
    font-weight: bolder;
    font-size: 1.7em;
    
}
@media screen and (max-width:1094px) {
    .card{
        height:360px;
    }
}
@media screen  and (max-width:945px){
    .card{
        height:460px
    }
}
@media screen and (max-width:768px) {
    .card{
        height: 350px;
    }
}
@media screen and (max-width:440px) {
    .card{
        height: 450px;
    }
}
@media screen and (max-width:650px) {
    .main-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>