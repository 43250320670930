<template>
    <div>
        <section class="container about" id="about">
        <div class="row">
            <div class="col-sm-6 px-4 col-md-6 top-container" data-aos="fade-right" data-aos-duration="3500"> 
                <h3><b>About</b></h3>
                <p class="pt-2 top-text">
                  Credmap helps organisations build institutional credibility and integrity in the complex world of multilateral governance.
                </p>
                <p class="top-text">
                     Credmap's two main divisions - the "ecosystems technology" design lab and R&D unit, and its management consulting unit, work hand in
                     hand to invent and help build solutions that boost trust, contribute to solving poverty, promote equity, address injustice and facilitate socioeconomic development through enhanced transparency, efficiency, accountability and power devolution
                </p>
            </div>
            <div class="col-sm-6 col-md-6" data-aos="fade-left" data-aos-duration="3000">
                <div class="row">
                    <div class="col-sm-12 fiscoins">
                        <a href="https://credgraph.credmap.com/GH" target="_blank">
                            <img src="../assets/rsz_21credmap_homepage_1.jpg" class="img-fluid" alt="credgraph">
                        </a>
                    </div>
                    <div class="col-sm-12 pt-2 credgraph">
                        <a href="https://fiscoins.com/" target="_blank">
                            <img src="../assets/rsz_31credmap_homepage_1.jpg" class="img-fluid" alt="fiscoins">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 bg-color" id="advisory-board">
        <div class="row" data-aos="flip-up" data-aos-duration="3000">
            <div class="col-sm-12">
                <div class="container">
                    <h3><b>Credmap | Vigillance</b></h3>
                    <div class="card">
                        <div class="card-body">
                              <p class="pt-2 top-text">
                                The Vigillance division of Credmap promises our clients and partners exceptional dedication
                                and service in transforming visions into emotionally authentic tangibles, enriched by our
                                exceptional understanding of the technologies, ideas, narratives and value-systems shaping the
                                world.
                            </p>
                            <p class="top-text">
                                Vigilance is not merely a strategy, management or operations consulting entity, it is a
                                systems-building partner for every changemaking organisation breaking new ground in a high-
                                stakes, complex, terrain. Vigillance designs, executes and navigates across entrenched lines of
                                difference, generating new value streams to resource the ambitious plans our partners have for
                                the worlds they are shaping.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>

.top-text {
    line-height: 2em;
    font-size: 1.2em;
}
.top-container {
    padding-top: 50px;
    padding-bottom:50px;
}
.card p{
    color:#000 !important;
    font-size: 1.2em;
    line-height: 1.7em;
}
.about{
    padding:50px 0 50px 0;
}
.bg-color{
    background: #1fa0f1;
    padding:100px 0 !important;
};
@media screen and (max-width:768px) {
   .about{
       padding:-50px 0 !important;
   } 
   .top-container{
       padding-top:0;
       padding-bottom: 0;
   }
}
</style>