<template>
    <div class="hero" id="home">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-9 pt-5" data-aos="zoom-in" data-aos-duration="3000">
                    <h2><b>
                        Inventing the technologies binding ecosystems together for socioeconomic progress.
                        Helping organisations build institutional credibility and integrity in the complex world of
                        multilateral governance.
                    </b></h2> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
.hero{
    background:url('../assets/CREDMAP LANDNG PAGE.jpg') center center;
    height: 750px;
    padding-top: 200px;
}
h2{
    font-weight: 900;
    color:#000;
    font-size: 3em;
}
p{
    color:#fff;
    font-weight: 1000;
}
@media screen and (max-width:600px) {
    .hero{
        padding-top:100px !important;
        padding-bottom:50px;
        text-align: center;
        height:100%;
    }
    h2{
        font-size: 2.4em;
    }
}
</style>